<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

        <a-row :gutter="24" class="px-20">

            <a-col :span="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb-24 mt-10">
                <h5>List of CSV Users</h5>
            </a-col>

            <a-col :span="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb-24 mt-10 text-right">
                <!-- <a-button :loading="loadingDownload" id="deleteBtn" class="text-danger" @click="confirmAllMembersDeletion" style="">
                    <a-icon type="download" theme="outlined" style="font-size: 14px;" /> &nbsp;&nbsp; Delete All
                </a-button> -->

                <a-button :loading="loadingDownload" id="btnBordered" type="text" class="mr-20" @click="downloadCSV" style="">
                    <a-icon type="download" theme="outlined" style="font-size: 14px;" /> &nbsp;&nbsp; CSV Template
                </a-button>

                <a-button :loading="loadingDownload" type="primary" @click="onUploadStudentIddentifications" style="">
                    <a-icon type="upload" theme="outlined" style="font-size: 14px;" /> &nbsp;&nbsp; Upload Identifications
                </a-button>
            </a-col>

            
        </a-row>

		<div class="mb-20 mt-20">

			<a-row :gutter="24" class="px-20">

				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 pb-10">
					<a-card>
                        
                        <a-row :gutter="24" class="mb-0 pb-0 mt-15">
                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0">
                                <a-table
                                    class="ant-table-striped"
                                    :columns="columns" 
									:data-source="students"
									:loading="loadingData" 
									:pagination="false" 
									:rowKey="record => record.uuid"
									width="100%" 
									style="width: 100%;">


                                        <template slot="s_no" slot-scope="s_no, record, index">
                                            {{ (pagination.currentPage - 1) * pagination.perPage + index + 1 }}
                                        </template>

                                        <template slot="fullName" slot-scope="row"> 
                                            {{ row.fullName }}
                                        </template>

                                        <template slot="externalId" slot-scope="row"> 
                                            {{ row.externalId }}
                                        </template>

                                        <template slot="extraExternalId" slot-scope="row"> 
                                            {{ row.extraExternalId }}
                                        </template>

                                        <template slot="hasJoined" slot-scope="row"> 
                                            <a-badge  
                                                style="font-size: 10px !important; margin: 2px 3px;" 
                                                :count="row.hasJoined ? 'Yes' : 'No'" 
                                                :number-style="row.hasJoined ? { backgroundColor: '#734EBF', color: '#fff', boxShadow: '0 0 0 1px #734EBF inset'} : { backgroundColor: '#D9D9D9', color: '#444' }"/>
                                        </template>

                                        <template slot="verifiedAt" slot-scope="row"> 
                                            {{ row.verifiedAt != null ? $Moment(row.lastSeen).format('DD MMM YYYY HH:mm') : '' }}
                                        </template>

                                        <template slot="action" slot-scope="row"> 
                                            <a-button
                                                id="deleteBtn"
                                                type="text"
                                                class="ml-15"
                                                size="small"
                                                @click="confirmMemberDeletion(row.uuid)"
                                                style="border-radius: 20px;">
                                                <a-icon type="delete" theme="outlined" />
                                            </a-button>
                                        </template>
                                        
                                </a-table>
                            </a-col>
                        </a-row>
                        
                    </a-card>
				</a-col>

                <a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 pb-10 text-right">
                    <a-pagination type="success" class="btn btn-silabu" v-model="currentPage" :total="pagination.total" :pageSize="pagination.perPage" show-less-items />
                </a-col>

			</a-row>

		</div>

	
        <!-- <div v-if="!loadingData && students.length == 0">

				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 50vh">

					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

						<img src="../../../../public/images/no_data.png" alt="" width="60%" style="" class=""> 

						<h6 class="mt-5" style="font-weight: 600; color: #8C8C8C">No members found</h6>
						
					</a-col>

				</a-row>
			</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 60vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div> -->


        <!-- FORM MODAL -->
		<a-modal v-model="studentIdentifications.showModal" :footer="null">

			<h4 class="text-center">Upload CSV</h4>

			<a-form
				class="login-form"
				:hideRequiredMark="true">

                <a-form-item class="mb-5" label="File" :colon="false">
                    <a-input 
                        type="file"
                        ref="file"
                        accept=".csv"
                        v-decorator="['file',
                            { rules: [{ required: true, message: 'Please upload file!' }] },
                        ]" placeholder="File" 
                        @change="handleSelectFile( $event )"/>
                </a-form-item>

                <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 10vh" v-if="studentIdentifications.loadingFile">

					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center justify-items-center">

						<a-spin class="text-primary py-20" size="medium"/>
						
					</a-col>

				</a-row>
				
			
                <a-button v-if="studentIdentifications.data.length > 0 && studentIdentifications.file != null && !studentIdentifications.loadingFile" type="primary" :loading="studentIdentifications.loading" @click="checkPayload" class="login-form-button text-white mt-20">
                    Submit {{ studentIdentifications.loading ? `${percentUpload}%` : '' }}
                </a-button>

			</a-form>
			
		</a-modal>


        
        <!--MEMBER DELETE CONFIRMATION -->
        <a-modal v-model="memberDeletion.showModal" :footer="null" width="416px">

            <a-row :gutters="24" class="mt-20">
                <a-col :span="24" :md="3" :lg="3" :xl="3" class="mt-20">
                    <a-icon type="warning" theme="outlined" class="text-warning" style="font-size: 25px;"/>
                </a-col>

                <a-col :span="24" :md="21" :lg="21" :xl="21">

                    <a-row :gutters="24">
                        <a-col :span="24">
                            <h6>Do you want to delete this csv member in <span class="text-danger">{{ classDetails.name }}</span> Class?</h6>
                            <p>Once you delete, you will not be able to recover these inforamtion.</p>
                        </a-col>
                    </a-row>

                    <a-row :gutters="24">
                        <a-col :span="24" class="text-right">
                            <a-button id="txtBtnCancel" type="text" class="text-secondary" @click="cancelMemberDeletion">
                                Cancel
                            </a-button>
                            &nbsp;&nbsp;
                            <a-button id="txtBtnDelete" :loading="memberDeletion.loading" type="text" class="text-danger" @click="handleDeleteMember">
                                Delete
                            </a-button>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
    
        </a-modal>


        <!--ALL CSV MEMBER DELETE CONFIRMATION -->
        <a-modal v-model="allMemberDeletion.showModal" :footer="null" width="416px">

            <a-row :gutters="24" class="">
                <a-col :span="24" :md="3" :lg="3" :xl="3" class="mt-10">
                    <a-icon type="warning" theme="outlined" class="text-warning" style="font-size: 25px;"/>
                </a-col>

                <a-col :span="24" :md="21" :lg="21" :xl="21">

                    <a-row :gutters="24">
                        <a-col :span="24">
                            <h6>Do you want to delete all imported csv members in <span class="text-danger">{{ classDetails.name }}</span> Class?</h6>
                            <p>Once you delete, you will not be able to recover these inforamtion.</p>
                        </a-col>
                    </a-row>

                    <a-row :gutters="24">
                        <a-col :span="24" class="text-right">
                            <a-button id="txtBtnCancel" type="text" class="text-secondary" @click="cancelAllMembersDeletion">
                                Cancel
                            </a-button>
                            &nbsp;&nbsp;
                            <a-button id="txtBtnDelete" :loading="allMemberDeletion.loading" type="text" class="text-danger" @click="handleDeleteAllMembers">
                                Delete
                            </a-button>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
    
        </a-modal>



	</div>
</template>

<script>
	import Papa from 'papaparse';
    import { notification } from 'ant-design-vue';


    const columns = [
        {
            title: 'S/N',
            scopedSlots: { customRender: 's_no' },
            width: 35
        },
        {
            title: 'Full Name',
            scopedSlots: { customRender: 'fullName' },
            width: 200
        },
        {
            title: 'External Id',
            scopedSlots: { customRender: 'externalId' },
            width: 100
        },
        {
            title: 'Extra External Id',
            scopedSlots: { customRender: 'extraExternalId' },
            width: 150
        },
        {
            title: 'Has Joined',
            scopedSlots: { customRender: 'hasJoined' },
            width: 100
        },
        {
            title: 'Verified At',
            scopedSlots: { customRender: 'verifiedAt' },
            width: 100
        },
        {
            title: 'Action',
            scopedSlots: { customRender: 'action' },
            width: 80
        },
    ];
	
	export default ({
		
		components: {},
        props: ['classDetails', 'isCreator'],
		data() {
			return {

                columns,
				loadingData: false,
                loadingDownload: false,

                chunkSize: 5000,
                percentUpload: 0,

                userDetails: {},
                institution: {},
				students: [],
				currentPage: 1,
                pagination: {
					search: '',
                    perPage: 10,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },

				slider: {
                    max: 40000,
                    min: 0,
                    step: 500
                },

                filter: {
                    loading: false,
                    showModal: false,
                    isActive: false,
                    type: null,
                    price: 20000,
                    tutorRating: 3,
                    startTime: null,
                },



                studentIdentifications: {
                    file: null,
                    parsed: false,
                    data: [],
                    loadingFile: false,
                    loading: false,
                    showModal: false,
                },

                memberDeletion: {
                    uuid: null,
                    loading: false,
                    showModal: false,
                },

                allMemberDeletion: {
                    loading: false,
                    showModal: false,
                }

      		}
		},
		watch: {
            // pagination: {
				
            //     handler() {
            //         // this.getMembers();
			// 		console.log(this.pagination.currentPage)
            //     },
            // },
			currentPage: {
                handler() {
					console.log(this.currentPage)
					this.pagination.currentPage = this.currentPage;
					this.getMembers()
                },
            },
            deep: true,
        },
		created() {
            this.initializeFilter();
            this.getInstitutionDetails();
			this.getMembers()
		},
		methods: {

			formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },

			submitSearch() {
				this.pagination.currentPage = 1;
				this.getMembers();
			},

			cancelSearch() {
				this.pagination.search = '';
				this.getMembers();
			},

			launchFilter() {
                this.filter.showModal = true;
            },

            clearFilter() {
                this.filter.isActive = false;
                this.filter.showModal = false;
                this.getMembers();
            },

            submitFilter() {
                this.filter.isActive = true;
                this.filter.showModal = false;
                this.getMembers();
            },

            async initializeFilter() {
                let filter = JSON.stringify(await localStorage.getItem('filter'))

                if(filter != null) {
                    if(filter.navigate == true) {
                        filter.navigate = false;
                        await localStorage.setItem('filter', JSON.stringify(filter))
                        
                        this.filter.price = filter.price;
                        this.filter.tutorRating = filter.tutorRating;
                        this.filter.startTime = filter.startTime;
                    }
                }
            },

            async getInstitutionDetails() {

                console.log(localStorage.getItem('user_token'))
				const institutionInfo = await localStorage.getItem('institution_details')

				if(institutionInfo != null) {
					let institutionDetails = JSON.parse(institutionInfo);

					console.log(institutionDetails)

					this.institution = institutionDetails;
				
				}

				const userInfo = await localStorage.getItem('user_details')

				if(userInfo != null) {
					let userDetails = JSON.parse(userInfo);

                    this.userDetails = userDetails;

				}
				
			},

			async getMembers() {

				this.loadingData = true;

                const { currentPage, perPage, search } = this.pagination;

                let  url = `${this.$BACKEND_URL}/institutions/users?clazz=${this.$route.params.uuid}&populate=true&page=${currentPage}&size=${perPage}`;
                 
                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.students = response.data.data;
						this.pagination.totalPages = response.data.pagination != null ? response.data.pagination.totalPages : 1;
                        this.pagination.total = response.data.pagination != null ? response.data.pagination.totalItems : 0;
                        this.pagination.currentPage = response.data.pagination != null ? response.data.pagination.currentPage : 1;
						this.currentPage = response.data.pagination != null ? response.data.pagination.currentPage : 1;
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },

            async downloadCSV() {
				this.loadingDownload = true;

				let url = `${this.$BACKEND_LEGACY_URL}/downloads/Identification-Template.csv`;

				window.location.href = url

				this.loadingDownload = false;
			},

            getFewSubjectList(subjectsList) {
                let newList = [subjectsList[0], subjectsList[1]]

                return newList
            },

            onUploadStudentIddentifications() {
                this.studentIdentifications.data = [];
                this.studentIdentifications.showModal = true;
            },


            handleSelectFile(event){
				this.studentIdentifications.file = event.target.files[0];
				this.parseFile();
			},


            parseFile(){
				this.studentIdentifications.data = [];
                
                this.studentIdentifications.loadingFile = true;
				Papa.parse( this.studentIdentifications.file, {
					header: true,
					skipEmptyLines: true,
                    escapeFormulae: false,
					complete: function( results ){
                        this.studentIdentifications.loadingFile = true;
						
						if(results.data && results.data[0].Identification != null)  {
							
							results.data.forEach(element => {

								if(element.Identification != null || element.Identification != "") {
									console.log(element.Contact)
									
									this.studentIdentifications.data.push({
                                        institution: this.institution.uuid,
                                        clazz: this.$route.params.uuid,
                                        fullName: element.Full_Name,
                                        externalId:  element.Identification != null ? element.Identification.trim() :  element.Identification, 
                                        extraExternalId: element.Contact != null ? this.formatContact(element.Contact.trim()) : element.Contact,
                                        hasJoined: false,
                                        creator: this.userDetails.uuid,
                                    })
								}
								
							});

                            this.studentIdentifications.loadingFile = false;
						}else{
							console.log("================>")
							this.notify('Please add full name, identification and contact of the student and try again uploading!!!', 'error')

                            this.studentIdentifications.loadingFile = false;
                        }
						
						this.studentIdentifications.parsed = false;
					}.bind(this)
				} );
			},


            formatContact(contact) {
                if(contact.includes('@')) {
                    return contact;
                }else{
                    return contact[0] == '+' ? contact : `+${contact}`;
                }
            },

            sleep(time) { new Promise(resolve => setTimeout(resolve, time))},  

            async checkPayload() {
                this.studentIdentifications.loading = true;

                // let chunk = this.studentIdentifications.data.slice(0, this.chunkSize);

                // const size = new TextEncoder().encode(JSON.stringify(chunk)).length
                // const kiloBytes = size / 1024;
                // const megaBytes = kiloBytes / 1024;

                // console.log(megaBytes.toFixed(0),' MB')
                // console.log(kiloBytes.toFixed(0), 'KB')

                if(this.studentIdentifications.data.length > this.chunkSize) {
                    let results = await this.splitPayload()

                    let iterator = 0;

                    

                    while (iterator < results.length) {

                        
                        console.log("new iteration")
                        const task = new Promise((resolve, reject) => {
                        // things that take long go here…
                            const duration = 60000;

                            this.smallPayloadHandleSubmit(results[iterator])

                            setTimeout(() => {
                                console.log(`done, task took ${duration/1000}sec`)
                                resolve()
                            }, duration)
                        })
                        // wait until task is finished but at least 2 seconds
                        await Promise.all([task, this.sleep(60000)])

                        this.percentUpload = (iterator/(results.length - 1) * 100).toFixed(1)

                        if(this.percentUpload == 100 || this.percentUpload == 100.0) {
                            this.studentIdentifications.loading = false;

                            this.studentIdentifications.loading = false;
                            this.studentIdentifications.showModal = false;

                            this.notify('success', "Members have been uploaded successfully")

                            // this.form.resetFields()

                            this.getMembers()

                        }

                        iterator++;
                    }

                    // await results.forEach(async item => {
                    //     // let response = 

                    //     await this.smallPayloadHandleSubmit(item)

                    //     // setTimeout(async () => {
                            
                    //     // }, 30000);
                    //     // if (item !== results[results.length - 1]) {
                    //         await new Promise(resolve => setTimeout(resolve, 30000)); // Wait for 30 seconds before sending the next chunk
                    //     // }
                        

                    //     // if(response == false){
                    //     //     this.studentIdentifications.loading = false;
                    //     //     return;
                    //     // }
                    // });
                    // this.studentIdentifications.loading = false;
                    // this.handleSubmit()
                }else{
                    let result = await this.splitPayload()

                    console.log(result)  
                    this.studentIdentifications.loading = false;
                }
            },


            async splitPayload() {

                
                const result = [];

                console.log(this.studentIdentifications.data)

                await this.studentIdentifications.data.forEach((item, index) => {
                    const chunkIndex = Math.floor(index / this.chunkSize);
                    if (!result[chunkIndex]) {
                    result[chunkIndex] = [];
                    }
                    result[chunkIndex].push(item);
                });

                // for (let i = 0; i < this.studentIdentifications.length; i += this.chunkSize) {
                //     const chunk = this.studentIdentifications.slice(i, i + this.chunkSize);
                //     result.push(chunk);
                // }

                return result
            },


            handleSubmit() {
					
                this.studentIdentifications.loading = true;

                let url = `${this.$BACKEND_URL}/institutions/users`;

                this.$AXIOS.post(url, this.studentIdentifications.data).then((response) => {
                    
                    this.studentIdentifications.loading = false;
                    this.studentIdentifications.showModal = false;

                    this.notify('success', "Members have been uploaded successfully")

                    // this.form.resetFields()

                    this.getMembers()

                    // this.loadingData = false;

                }).catch(async(error) => {

                    console.log(error)

                    this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
                    
                    this.studentIdentifications.loading = false;

                    if(error.response && error.response.status == 401) {
                        await localStorage.setItem("user_token", null);
                        await localStorage.setItem("user_details", null)
                        await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });

				
			},



            async smallPayloadHandleSubmit(payload) {
					
                this.studentIdentifications.loading = true;

                let url = `${this.$BACKEND_URL}/institutions/users`;

                await this.$AXIOS.post(url, payload).then((response) => {
                    
                    // this.studentIdentifications.loading = false;
                    // this.studentIdentifications.showModal = false;

                    // this.notify('success', "Members have been uploaded successfully")

                    // this.form.resetFields()

                    // this.getMembers()

                    // this.loadingData = false;

                    // return true

                }).catch(async(error) => {

                    console.log(error)

                    // this.handleDeleteAllMembers()



                    this.notify('error', "Failed to upload members")

                    // return false;
                });

                
            },


            confirmMemberDeletion(memberUuid) {
                this.memberDeletion.uuid = memberUuid;
                this.memberDeletion.showModal = true;
                this.memberDeletion.loading = false;
            },


            cancelMemberDeletion() {
                this.memberDeletion.uuid = null;
                this.memberDeletion.loading = false;
                this.memberDeletion.showModal = false;
            },


            async handleDeleteMember() {

                this.memberDeletion.loading = true;

				let url = `${this.$BACKEND_URL}/institutions/users/${this.memberDeletion.uuid}`

				this.$AXIOS.delete(url).then(async(response) => {

                    this.cancelMemberDeletion()
					
					this.notify('success', 'Csv Member has been deleted successfully')
					
					this.getMembers()

				}).catch(async(error) => {
                    console.log(error)
					
					this.cancelMemberDeletion()

					this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
                    
					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});

			},




            confirmAllMembersDeletion() {
                this.allMemberDeletion.showModal = true;
                this.allMemberDeletion.loading = false;
            },



            cancelAllMembersDeletion() {
                this.allMemberDeletion.loading = false;
                this.allMemberDeletion.showModal = false;
            },

            async handleDeleteAllMembers() {

                this.allMemberDeletion.loading = true;

				let url = `${this.$BACKEND_URL}/institutions/users/all/${this.$route.params.uuid}`

				this.$AXIOS.delete(url).then(async(response) => {

                    this.cancelAllMembersDeletion()
					
					this.notify('success', 'All Csv Members has been deleted successfully')
					
					this.getMembers()

				}).catch(async(error) => {
					
					this.cancelAllMembersDeletion()

					this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
                    
					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});

			},
            

            notify(type, message) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 5,
                });
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	/* border-radius: 10px; */
}

a:hover {
    color: inherit !important;
}


#txtBtnCancel, #txtBtnCancel:hover {
	color: #B37FEA;
	border: none;
}

#txtBtnDelete, #txtBtnDelete:hover {
	color: #F5212D;
	border: none;
}

#deleteBtn, #deleteBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #F5222D;
}
</style>