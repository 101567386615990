<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

        <a-row :gutter="24" class="px-20">

				<a-col :span="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb-24 mt-10">
					<h5>List of Classmates</h5>
				</a-col>

                <!-- <a-col :span="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb-24 mt-10 text-right">
					<a-button :loading="loadingDownload" id="btnBordered" type="text" class="mr-20" @click="downloadCSV" style="">
                        <a-icon type="download" theme="outlined" style="font-size: 14px;" /> &nbsp;&nbsp; CSV Template
                    </a-button>

                    <a-button :loading="loadingDownload" type="primary" @click="onUploadStudentIddentifications" style="">
                        <a-icon type="upload" theme="outlined" style="font-size: 14px;" /> &nbsp;&nbsp; Upload Identifications
                    </a-button>
				</a-col> -->

                
			</a-row>

		<div v-if="!loadingData" class="mb-20">

			<a-row :gutter="24" class="px-20">

				<a-col :span="24" :xs="24" :sm="24" :md="24" :lg="12" :xl="8" class="mb-24 pb-10" v-for="memb in classMembers" :key="memb.uuid">
					<a-card class="pb-15">
                        <a class="text-dark" style="text-decoration: none !important;" :href="`/tutors/view/${memb.uuid}`">
                            <a-row :gutter="24">
                                <a-col :span="24" :xs="24" :sm="12" :md="8" :lg="8" :xl="8" class="text-center">
                                    <a-avatar :size="70" class="mt-5" :src="memb.profile" />
                                </a-col>

                                <a-col :span="24" :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
                                    <a-row :gutter="24" class="pt-10">
                                        <a-col :span="24">
                                            <h6 class="mb-0 pb-0">{{ `${formatName(memb.firstName)} ${formatName(memb.lastName)}`}}</h6>
                                        </a-col>
                                        <a-col :span="24" class="mt-0">
                                            <span> {{ classDetails.creator != null && classDetails.creator.uuid == memb.uuid ? 'Tutor' : memb.isCoTutor ? 'Co-Tutor' : 'Student' }}</span>
                                            <!-- <svg v-for="(option, index) in 4" :key="index" class="dark:text-white" style="color: #F9AA34; width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                <path d="M13.8 4.2a2 2 0 0 0-3.6 0L8.4 8.4l-4.6.3a2 2 0 0 0-1.1 3.5l3.5 3-1 4.4c-.5 1.7 1.4 3 2.9 2.1l3.9-2.3 3.9 2.3c1.5 1 3.4-.4 3-2.1l-1-4.4 3.4-3a2 2 0 0 0-1.1-3.5l-4.6-.3-1.8-4.2Z"/>
                                            </svg>
                                            <svg class="dark:text-white" style="color: #F9AA34; width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <path stroke="currentColor" stroke-width="2" d="M11 5.1a1 1 0 0 1 2 0l1.7 4c.1.4.4.6.8.6l4.5.4a1 1 0 0 1 .5 1.7l-3.3 2.8a1 1 0 0 0-.3 1l1 4a1 1 0 0 1-1.5 1.2l-3.9-2.3a1 1 0 0 0-1 0l-4 2.3a1 1 0 0 1-1.4-1.1l1-4.1c.1-.4 0-.8-.3-1l-3.3-2.8a1 1 0 0 1 .5-1.7l4.5-.4c.4 0 .7-.2.8-.6l1.8-4Z"/>
                                            </svg> -->
                                        </a-col>
                                    </a-row> 

                                    <a-row :gutter="24" class="mt-5">
                                        <!-- <a-col :span="24" :md="24" :lg="24" :xl="24">
                                            <small class="description">Teaches: <strong>Online, At Home</strong></small>
                                        </a-col> -->

                                        <a-col :span="24" :md="24" :lg="24" :xl="24" class="mt-0 pt-0">
                                            <small>Last seen {{ $Moment(memb.lastSeen).format('DD MMM')}}</small>
                                        </a-col>
                                    </a-row>

                                    
                                </a-col>

                            </a-row>
                        </a>
                        
                    </a-card>
				</a-col>

			</a-row>

		</div>


        <div v-if="!loadingData && classMembers.length == 0">

				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 50vh">

					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

						<img src="../../../../public/images/no_data.png" alt="" width="60%" style="" class=""> 

						<h6 class="mt-5" style="font-weight: 600; color: #8C8C8C">No members found</h6>
						
					</a-col>

				</a-row>
			</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 60vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>


        <!-- FORM MODAL -->
		<a-modal v-model="studentIdentifications.showModal" :footer="null">

			<h4 class="text-center">Upload CSV</h4>

			<a-form
				class="login-form"
				:hideRequiredMark="true">

                <a-form-item class="" label="File" :colon="false">
                    <a-input 
                        type="file"
                        ref="file"
                        accept=".csv"
                        v-decorator="['file',
                            { rules: [{ required: true, message: 'Please upload file!' }] },
                        ]" placeholder="File" 
                        @change="handleSelectFile( $event )"/>
                </a-form-item>

                <a-spin v-if="studentIdentifications.loadingFile" class="text-primary py-20" size="small"/>
				
			
                <a-button v-if="studentIdentifications.data.length > 0 && studentIdentifications.file != null && !studentIdentifications.loadingFile" type="primary" :loading="studentIdentifications.loading" @click="handleSubmit" class="login-form-button text-white">
                    Submit
                </a-button>

			</a-form>
			
		</a-modal>

	</div>
</template>

<script>
	import Papa from 'papaparse';
    import { notification } from 'ant-design-vue';
	
	export default ({
		
		components: {},
        props: ['classDetails', 'isCreator'],
		data() {
			return {

				loadingData: false,
                loadingDownload: false,

                userDetails: {},
                institution: {},
				classMembers: [],
				currentPage: 1,
                pagination: {
					search: '',
                    perPage: 9,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },

				slider: {
                    max: 40000,
                    min: 0,
                    step: 500
                },

                filter: {
                    loading: false,
                    showModal: false,
                    isActive: false,
                    type: null,
                    price: 20000,
                    tutorRating: 3,
                    startTime: null,
                },



                studentIdentifications: {
                    file: null,
                    parsed: false,
                    data: [],
                    loadingFile: false,
                    loading: false,
                    showModal: false,
                },

      		}
		},
		watch: {
            // pagination: {
				
            //     handler() {
            //         // this.getMembers();
			// 		console.log(this.pagination.currentPage)
            //     },
            // },
			currentPage: {
                handler() {
					console.log(this.currentPage)
					this.pagination.currentPage = this.currentPage;
					this.getMembers()
                },
            },
            deep: true,
        },
		created() {
            this.initializeFilter();
            this.getInstitutionDetails();
			this.getMembers()
		},
		methods: {

			formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },

			submitSearch() {
				this.pagination.currentPage = 1;
				this.getMembers();
			},

			cancelSearch() {
				this.pagination.search = '';
				this.getMembers();
			},

			launchFilter() {
                this.filter.showModal = true;
            },

            clearFilter() {
                this.filter.isActive = false;
                this.filter.showModal = false;
                this.getMembers();
            },

            submitFilter() {
                this.filter.isActive = true;
                this.filter.showModal = false;
                this.getMembers();
            },

            async initializeFilter() {
                let filter = JSON.stringify(await localStorage.getItem('filter'))

                if(filter != null) {
                    if(filter.navigate == true) {
                        filter.navigate = false;
                        await localStorage.setItem('filter', JSON.stringify(filter))
                        
                        this.filter.price = filter.price;
                        this.filter.tutorRating = filter.tutorRating;
                        this.filter.startTime = filter.startTime;
                    }
                }
            },

            async getInstitutionDetails() {

                console.log(localStorage.getItem('user_token'))
				const institutionInfo = await localStorage.getItem('institution_details')

				if(institutionInfo != null) {
					let institutionDetails = JSON.parse(institutionInfo);

					console.log(institutionDetails)

					this.institution = institutionDetails;
				
				}

				const userInfo = await localStorage.getItem('user_details')

				if(userInfo != null) {
					let userDetails = JSON.parse(userInfo);

                    this.userDetails = userDetails;

				}
				
			},

			async getMembers() {

				this.loadingData = true;

                const { currentPage, perPage, search } = this.pagination;

                let  url = `${this.$BACKEND_URL}/members?clazz=${this.$route.params.uuid}&populate=true&page=${currentPage}&size=${perPage}`;
                 
                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.classMembers = response.data.data;
						this.pagination.totalPages = response.data.pagination != null ? response.data.pagination.totalPages : 1;
                        this.pagination.total = response.data.pagination != null ? response.data.pagination.totalItems : 0;
                        this.pagination.currentPage = response.data.pagination != null ? response.data.pagination.currentPage : 1;
						this.currentPage = response.data.pagination != null ? response.data.pagination.currentPage : 1;
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },

            async downloadCSV() {
				this.loadingDownload = true;

				let url = `${this.$BACKEND_LEGACY_URL}/downloads/Identification-Template.csv`;

				window.location.href = url

				this.loadingDownload = false;
			},

            getFewSubjectList(subjectsList) {
                let newList = [subjectsList[0], subjectsList[1]]

                return newList
            },

            onUploadStudentIddentifications() {
                this.studentIdentifications.data = [];
                this.studentIdentifications.showModal = true;
            },


            handleSelectFile(event){
				this.studentIdentifications.file = event.target.files[0];
				this.parseFile();
			},


            parseFile(){
				this.studentIdentifications.data = [];
                this.studentIdentifications.loadingFile = true;
				Papa.parse( this.studentIdentifications.file, {
					header: true,
					skipEmptyLines: true,
					complete: function( results ){
						
						if(results.data && results.data[0].Identification != null)  {
							
							results.data.forEach(element => {

								if(element.Identification != null || element.Identification != "") {
									console.log(element)
									
									this.studentIdentifications.data.push({
                                        institution: this.institution.uuid,
                                        clazz: this.$route.params.uuid,
                                        fullName: element.Full_Name,
                                        externalId: element.Identification, 
                                        extraExternalId: element.Extra_Identification,
                                        hasJoined: false,
                                        creator: this.userDetails.uuid,
                                    })
								}
								
							});

                            this.studentIdentifications.loadingFile = false;
						}else{
							console.log("================>")
							this.notify('error', 'Make sure the First Cell(A1) and the Second Cell(B1) are labeled with Identification and Extra_Identification respectively as template provided and try again!!!')

                            this.studentIdentifications.loadingFile = false;
                        }
						
						this.studentIdentifications.parsed = true;
					}.bind(this)
				} );
			},


            handleSubmit() {
					
                this.studentIdentifications.loading = true;

                let url = `${this.$BACKEND_URL}/institutions/users`;

                this.$AXIOS.post(url, this.studentIdentifications.data).then((response) => {
                    
                    this.studentIdentifications.loading = false;
                    this.studentIdentifications.showModal = false;

                    this.notify('success',  "Details have been saved successfully", )

                    this.form.resetFields()

                    // this.getMembers

                    // this.loadingData = false;

                }).catch(async(error) => {

                    this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
                    
                    this.studentIdentifications.loading = false;

                    if(error.response && error.response.status == 401) {
                        await localStorage.setItem("user_token", null);
                        await localStorage.setItem("user_details", null)
                        await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });

				
			},


            notify(type, message) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 5,
                });
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	/* border-radius: 10px; */
}

a:hover {
    color: inherit !important;
}
</style>