<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

		<div v-if="!loadingData" class="px-20 pt-10">

			<a-row :gutter="24" style="margin-top: 0px;">

				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="">
					
					<a-row class="px-20 mt-10 mb-20 pb-20">
						<a-col :span="24">
							<a-tabs v-model="activeTab" @change="onChangeActiveTab">
								<a-tab-pane key="class-attendance" tab="Class Attendance">
									<a-card class="px-5 pb-0 mb-0 mt-0">
										<ClassAttendance 
											:classDetails="classDetails" 
											:isCreator="classDetails.institution != null && institutionDetails.uuid != null && institutionDetails.uuid == classDetails.institution.uuid"
										/>
									</a-card> 
								</a-tab-pane>
                                <a-tab-pane key="notes-readers" tab="Notes Readers">
									<a-card class="px-5 pb-0 mb-0 mt-0">
										<NotesReaders 
											:classDetails="classDetails" 
											:isCreator="classDetails.institution != null && institutionDetails.uuid != null && institutionDetails.uuid == classDetails.institution.uuid"
										/>
									</a-card> 
								</a-tab-pane>
								<!-- <a-tab-pane key="lesson-readers" tab="Lesson Readers">
									<a-card class="px-5 pb-0 mb-0 mt-0">
										<LessonReaders 
											:classDetails="classDetails" 
											:isCreator="classDetails.institution != null && institutionDetails.uuid != null && institutionDetails.uuid == classDetails.institution.uuid"
										/>
									</a-card> 
								</a-tab-pane> -->
                                <a-tab-pane key="quiz-takers" tab="Quiz Takers">
									<a-card class="px-5 pb-0 mb-0 mt-0">
										<QuizTakers 
											:classDetails="classDetails" 
											:isCreator="classDetails.institution != null && institutionDetails.uuid != null && institutionDetails.uuid == classDetails.institution.uuid"
										/>
									</a-card> 
								</a-tab-pane>
							</a-tabs>
						</a-col>
					</a-row>

				</a-col>

			</a-row>
			

		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>
	</div>
</template>



<script>
    import ClassAttendance from "./ClassAttendance.vue";
    import NotesReaders from "./NotesReaders.vue";
    // import LessonReaders from "./LessonReaders.vue";
    import QuizTakers from "./QuizTakers.vue";

	export default ({
		
		components: {
            ClassAttendance,
			NotesReaders,
			// LessonReaders,
            QuizTakers,
		},
        props: ['classDetails', 'isCreator'],

		data() {
			return {

				loadingData: false,
				activeTab: 'class-attendance',

				userDetails: {},
				institutionDetails: {},

				socket: null,

				search: "",
				message: "Hello",
				api: null,
				room: "test romm",
				username: "rabie",
      		}
		},
		
		created() {
		},

		// watch: {
        //     socket: {
		// 		handler() {
		// 			this.socket.on("new-message", (data) => {
						
		// 			});
		// 		}
		// 	},
        //     deep: true,
        // },
		methods: {

			async onChangeActiveTab(activeTab) {
				await localStorage.setItem('activeTab', activeTab)
			},



		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	/* border-radius: 10px; */
}

#btn, #btn:hover {
	background-color: #734EBF !important;
	color: white !important;
}

/* .ant-tabs-nav:hover, .ant-tabs-tab-active {
	color: #734EBF !important;
	border-bottom: 1px solid#734EBF;
} */
</style>